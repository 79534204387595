@import 'colors_variables';
@import 'job_variables';

.job-chip {
  padding: 5px;
  border-radius: 4px;
  display: inline-block;
  font-weight: 500;
}

.job-assigned {
  background-color: $job-assigned-background-color;
  color: $job-status-black-font;
}

.job-draft {
  background-color: $job-draft-background-color;
  color: $job-status-black-font;
}

.job-unassigned {
  background-color: $job-unassigned-background-color;
  color: $job-status-black-font;
}

.job-completed {
  background-color: $job-completed-background-color;
  color: $job-status-white-font;
}

.job-inactive {
  background-color: $job-inactive-background-color;
  color: $job-status-white-font;
}

.job-cancelled {
  background-color: $job-cancelled-background-color;
  color: $job-status-white-font;
}
