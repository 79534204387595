/* You can add global styles to this file, and also import other style files */
@import "fleetoperate-theme";
@import "colors_variables";
@import "spacing_variables";

html {
  height: 100%;

  body {
    min-height: 100%;
    height: 100%;
    margin: 0;

    background-color: $body-color;
    color: $primary-font-color;
  }
}

.delivery-brand-title {
  img {
    height: 60px;
    width: 300px;
    margin-top: 20px;
  }
}

fleetoperate-delivery-shipper-app-toolbar + * {
  padding-top: $padding-md * 4;
}

.detail-panel {
  position: absolute !important;
  right: 0px;
  height: 100%;
  padding-top: 80px;
}

.detail-panel-md {
  @extend .detail-panel;
  display: block;
  width: 600px;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  &.dark-backdrop {
    background: #000;
    opacity: 0.85 !important;
  }
}
