@import 'colors_variables';

$job-status-black-font: $primary-font-color;

$job-status-white-font: $white;

$job-assigned-background-color: #9ccc65;

$job-draft-background-color: #ffff00;

$job-unassigned-background-color: #ffd600;

$job-completed-background-color: #1a237e;

$job-inactive-background-color: #f4511e;

$job-cancelled-background-color: #ba68c8;
